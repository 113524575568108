<template>
  <div id="app" class="app-body">
    <div>
      <div class="spacer-small"></div>
      <div class="columns is-vcentered">
        <div class="column p-5 has-text-centered">
          <img src="@/assets/img/misc/login.svg" />
        </div>
        <div class="column">
          <p class="is-title is-secondary is-bold">
            Create your <br /><span class="is-primary">Brandeeq</span> Account
          </p>
          <p class="py-4">
            Already have an account?
            <router-link :to="{ name: 'Login' }">
              Click here to sign in.
            </router-link>
          </p>

          <div v-for="(errorMessage, index) in errorMessages" :key="index">
            <b-message v-if="showErrorMessage" type="is-danger"
              ><i class="mdi mdi-information-outline pr-2"></i>
              {{ errorMessage }}</b-message
            >
          </div>

          <b-message v-if="showPasswordIncorrectMessage" type="is-danger"
            ><i class="mdi mdi-information-outline pr-2"></i> Passwords do not
            match</b-message
          >

          <form @submit.prevent="register" id="form">
            <div class="py-4">
              <b-field label="First Name">
                <b-input v-model="firstName" required></b-input>
              </b-field>
              <b-field label="Last Name">
                <b-input v-model="lastName" required></b-input>
              </b-field>
              <b-field label="Phone">
                <b-input v-model="contact" required></b-input>
              </b-field>
              <b-field label="E-mail">
                <b-input v-model="email" type="email" required></b-input>
              </b-field>
              <b-field label="Password">
                <b-input v-model="password" type="password" required></b-input>
              </b-field>
              <b-field label="Confirm Password">
                <b-input
                  v-model="confirmPassword"
                  type="password"
                  required
                ></b-input>
              </b-field>
            </div>
            <button form="form" class="button is-info" type="submit">
              Sign Up
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      firstName: undefined,
      lastName: undefined,
      email: undefined,
      password: undefined,
      contact: undefined,
      confirmPassword: undefined,
      isRegistering: false,
      showErrorMessage: false,
      showPasswordIncorrectMessage: false,
      errorMessages: undefined,
    };
  },
  methods: {
    async register() {
      this.showErrorMessage = false;
      this.showPasswordIncorrectMessage = false;
      this.errorMessages = undefined;
      this.isRegistering = true;
      if (this.password !== this.confirmPassword) {
        this.showPasswordIncorrectMessage = true;
        this.isRegistering = false;
        return;
      }
      const result = await this.$store.dispatch("customerRegister", {
        email: this.email,
        password: this.password,
        firstName: this.firstName,
        lastName: this.lastName,
        contact: this.contact,
      });
      if (result.errorMessages) {
        this.showErrorMessage = true;
        this.errorMessages = result.errorMessages;
      }
      this.isRegistering = false;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";
</style>
